import {
  aktivGroteskMediumMixin,
  CACTUS_100,
  OwnUpIcon,
  PropsWithTheme,
  SLATE_100
} from '@rategravity/own-up-component-library';
import { StarFilledIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/star-filled';
import React from 'react';
import styled from 'styled-components';

export const ReviewsSection = styled.section`
  background-color: white;
`;
export const Content = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;
export const Headline = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin: 0;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  text-align: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(870)} {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(705)} {
    max-width: 100%;
  }
`;
export const ReviewsWrapperMobile = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    display: none;
  }
`;

export const ReviewsWrapperDesktop = styled.div`
  display: none;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: block;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  }
`;

export const PagginationWrapper = styled.div`
  margin: 0 auto;
  display: block;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

const ReviewWrapper = styled.div`
  padding: 0;
  min-height: 411px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0, 4)};
    min-height: auto;
  }
`;
const ImageWrapper = styled.div`
  width: 148px;
  height: 148px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    width: 196px;
    height: 196px;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  }
`;
const ReviewDescription = styled.div`
  ${aktivGroteskMediumMixin}
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.01em;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;
const ReviewDetails = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;
const ReviewName = styled.div`
  color: ${SLATE_100};
`;

const RatingStarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0.5)}px;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;

export const Review = ({
  image,
  description,
  details,
  name
}: {
  image: JSX.Element;
  description: string;
  details: string;
  name: string;
}) => (
  <ReviewWrapper>
    <ImageWrapper>{image}</ImageWrapper>
    <RatingStarsContainer role="img" aria-label="5-star review">
      {[...Array(5)].map((_, i) => (
        <OwnUpIcon aria-hidden="true" icon={<StarFilledIcon />} color={CACTUS_100} key={i} />
      ))}
    </RatingStarsContainer>
    <ReviewDescription>{description}</ReviewDescription>
    <ReviewDetails>{details}</ReviewDetails>
    <ReviewName>{name}</ReviewName>
  </ReviewWrapper>
);
