import { OwnUpFillButtonPrimary, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';
import { dogEarBottomOffset } from '../elements';

const customBreakpoint = 1228;
const customMobileBreakpoint = 870;

export const Section = styled.section`
  background-color: white;
  color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.text.primary};

  ${dogEarBottomOffset}
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 0, 0)};
  gap: 0 32px;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0, 0)};
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1.5)}px;
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
  flex-direction: column;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    justify-content: center;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding-bottom: 0;
    justify-content: flex-start;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customBreakpoint)} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;
export const DesktopButtonWrapper = styled(ButtonWrapper)`
  display: none;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    display: flex;
  }
`;
export const MobileButtonWrapper = styled(ButtonWrapper)`
  display: flex;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;

export const Button = styled(OwnUpFillButtonPrimary)`
  width: 100%;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    width: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(19)}px;
  }
`;

export const DesktopCTAText = styled.span`
  display: none;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    display: initial;
  }
`;

export const MobileCTAText = styled.span`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;
export const ImageWrapper = styled.div`
  grid-column: 1;
  grid-row: 4;
  justify-self: center;
  min-height: calc(81vw - 48px);
  height: calc(100vh - 444px);
  overflow: hidden;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    min-height: unset;
    height: unset;
    overflow: unset;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    grid-row: 1 / span 2;
  }
`;
export const CTAArea = styled.div`
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  margin-left: 0;
  width: 100%;
  padding-top: 0;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    grid-column: 2 / span 2;
    max-width: 72%;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(9)}px;
  }
`;
export const CTAHeadline = styled.h1`
  font-size: 24px;
  line-height: 32px;
  margin: 0 auto;
  max-width: 312px;
  text-align: center;
  font-weight: bold;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    max-width: unset;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    max-width: 390px;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
    padding-right: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
    text-align: left;
    max-width: 100%;
    font-size: 48px;
    line-height: 56px;
  }
`;

export const CTASubHeadline = styled.h2`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-weight: 400;
  margin: 0;
`;

export const RatingInfo = styled.div`
  grid-column: 1;
  grid-row: 3;
  justify-self: center;
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  display: flex;
  justify-content: center;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  margin-left: 0;
  margin-bottom: 24px;
  width: 100%;
  max-width: 280px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    grid-column: 2 / span 2;
    grid-row: 2;
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
    margin-bottom: 66px;
    justify-content: flex-start;
    max-width: 72%;
  }
`;

export const RatingValue = styled.div`
  font-size: 26px;
  line-height: 32px;
  font-weight: bold;
  align-content: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    align-content: unset;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customBreakpoint)} {
    font-size: 48px;
    line-height: 64px;
  }
`;

export const RatingStarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0.5)}px;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0.5)}px;
`;

export const RatingDescription = styled.div`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customBreakpoint)} {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
`;

export const ApproveTime = styled.div`
  grid-column: 1;
  grid-row: 2;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  line-height: 24px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;
