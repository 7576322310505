import { MOSS_70, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

const customMobileBreakpoint = 870;

export const NewOverpaySection = styled.section`
  background-color: ${MOSS_70};
`;

export const Content = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const Headline = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin: 0;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  text-align: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    font-size: 48px;
    line-height: 56px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(705)} {
    max-width: 100%;
  }
`;

export const SubHeadline = styled.h3`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-weight: 400;
  margin: 0;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
    text-align: center;
  }
`;

export const OverpayItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    flex-direction: row;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const OverpayItem = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: left;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 64px;
`;

export const OverpayHeadline = styled.h3`
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const OverpaySubHeadline = styled.h4`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 0;
  font-weight: 400;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    max-width: 340px;
  }
`;

export const CTAWrapper = styled.div`
  text-align: center;
`;
