import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  CACTUS_100,
  OwnUpGridWrapper,
  OwnUpIcon,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import { StarFilledIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/star-filled';
import React, { PropsWithChildren } from 'react';
import TimerIcon from '../../../images/inline-svg/hero/timer.inline.svg';
import { HomepageHeroImage } from '../../static-images/home';

import {
  ApproveTime,
  Button,
  ButtonWrapper,
  Content,
  CTAArea,
  CTAHeadline,
  ImageWrapper,
  RatingDescription,
  RatingInfo,
  RatingStarsContainer,
  RatingValue,
  Section
} from './elements';

export const HeroMain = ({ children }: PropsWithChildren<{}>) => (
  <OwnUpThemeProvider>
    <Section aria-label="hero">
      <OwnUpGridWrapper>{children}</OwnUpGridWrapper>
    </Section>
  </OwnUpThemeProvider>
);

const HeroCTAGroup = () => {
  const trackingInstance = useTrackingInstance();
  const buyingOnClick = () => {
    trackingInstance.track('Click discover CTA', { 'page-location': `home-hero-buying` });
  };
  const refinanceOnClick = () => {
    trackingInstance.track('Click discover CTA', { 'page-location': `home-hero-refinancing` });
  };
  return (
    <>
      <ButtonWrapper>
        <Button
          id="buying_hero"
          href={`${process.env.GATSBY_ONBOARDING}/buying`}
          onClick={buyingOnClick}
        >
          Buying
        </Button>
        <Button
          id="refinance_hero"
          href={`${process.env.GATSBY_ONBOARDING}/refinance`}
          onClick={refinanceOnClick}
        >
          Refinancing
        </Button>
      </ButtonWrapper>
    </>
  );
};

export const PureHero = ({ Image }: { Image: () => JSX.Element }) => {
  return (
    <Content>
      <CTAArea>
        <CTAHeadline>Shop for your mortgage and save thousands</CTAHeadline>
        <HeroCTAGroup />
      </CTAArea>

      <ApproveTime>
        <TimerIcon />4 min | No credit impact
      </ApproveTime>

      <RatingInfo>
        <RatingValue>4.9</RatingValue>
        <div>
          <RatingStarsContainer role="img" aria-label="5-star review">
            {[...Array(5)].map((_, i) => (
              <OwnUpIcon aria-hidden icon={<StarFilledIcon />} color={CACTUS_100} key={i} />
            ))}
          </RatingStarsContainer>
          <RatingDescription>4,690 Verified Reviews</RatingDescription>
        </div>
      </RatingInfo>

      <ImageWrapper>
        <Image />
      </ImageWrapper>
    </Content>
  );
};

export const Hero = () => {
  return <PureHero Image={HomepageHeroImage} />;
};
