import { OwnUpGridWrapper, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import React from 'react';
import BloombergDesktopActive from '../../../images/inline-svg/news/desktop/active/bloomberg.inline.svg';
import HousingWireDesktopActive from '../../../images/inline-svg/news/desktop/active/housing-wire.inline.svg';
import NPRDesktopActive from '../../../images/inline-svg/news/desktop/active/npr.inline.svg';
import USATodayDesktopActive from '../../../images/inline-svg/news/desktop/active/usa-today.inline.svg';
import USNDesktopActive from '../../../images/inline-svg/news/desktop/active/usn.inline.svg';
import YahooFinanceDesktopActive from '../../../images/inline-svg/news/desktop/active/yahoo-finance.inline.svg';

import BloombergDesktopNotActive from '../../../images/inline-svg/news/desktop/not-active/bloomberg.inline.svg';
import HousingWireDesktopNotActive from '../../../images/inline-svg/news/desktop/not-active/housing-wire.inline.svg';
import NPRDesktopNotActive from '../../../images/inline-svg/news/desktop/not-active/npr.inline.svg';
import USATodayDesktopNotActive from '../../../images/inline-svg/news/desktop/not-active/usa-today.inline.svg';
import USNDesktopNotActive from '../../../images/inline-svg/news/desktop/not-active/usn.inline.svg';
import YahooFinanceDesktopNotActive from '../../../images/inline-svg/news/desktop/not-active/yahoo-finance.inline.svg';

import BloombergMobileActive from '../../../images/inline-svg/news/mobile/active/bloomberg.inline.svg';
import HousingWireMobileActive from '../../../images/inline-svg/news/mobile/active/housing-wire.inline.svg';
import NPRMobileActive from '../../../images/inline-svg/news/mobile/active/npr.inline.svg';
import USATodayMobileActive from '../../../images/inline-svg/news/mobile/active/usa-today.inline.svg';
import USNMobileActive from '../../../images/inline-svg/news/mobile/active/usn.inline.svg';
import YahooFinanceMobileActive from '../../../images/inline-svg/news/mobile/active/yahoo-finance.inline.svg';

import BloombergMobileNotActive from '../../../images/inline-svg/news/mobile/not-active/bloomberg.inline.svg';
import HousingWireMobileNotActive from '../../../images/inline-svg/news/mobile/not-active/housing-wire.inline.svg';
import NPRMobileNotActive from '../../../images/inline-svg/news/mobile/not-active/npr.inline.svg';
import USATodayMobileNotActive from '../../../images/inline-svg/news/mobile/not-active/usa-today.inline.svg';
import USNMobileNotActive from '../../../images/inline-svg/news/mobile/not-active/usn.inline.svg';
import YahooFinanceMobileNotActive from '../../../images/inline-svg/news/mobile/not-active/yahoo-finance.inline.svg';

import { Content, NewsHeadline, ResponsiveHoverImage } from './elements';

export const InNews = () => {
  return (
    <OwnUpThemeProvider>
      <section aria-label="OwnUpInNews">
        <OwnUpGridWrapper>
          <NewsHeadline>Featured in:</NewsHeadline>
          <Content>
            <ResponsiveHoverImage
              DesktopDefault={BloombergDesktopNotActive}
              DesktopHover={BloombergDesktopActive}
              MobileDefault={BloombergMobileNotActive}
              MobileHover={BloombergMobileActive}
            />
            <ResponsiveHoverImage
              DesktopDefault={HousingWireDesktopNotActive}
              DesktopHover={HousingWireDesktopActive}
              MobileDefault={HousingWireMobileNotActive}
              MobileHover={HousingWireMobileActive}
            />
            <ResponsiveHoverImage
              DesktopDefault={NPRDesktopNotActive}
              DesktopHover={NPRDesktopActive}
              MobileDefault={NPRMobileNotActive}
              MobileHover={NPRMobileActive}
            />
            <ResponsiveHoverImage
              DesktopDefault={USATodayDesktopNotActive}
              DesktopHover={USATodayDesktopActive}
              MobileDefault={USATodayMobileNotActive}
              MobileHover={USATodayMobileActive}
            />
            <ResponsiveHoverImage
              DesktopDefault={USNDesktopNotActive}
              DesktopHover={USNDesktopActive}
              MobileDefault={USNMobileNotActive}
              MobileHover={USNMobileActive}
            />
            <ResponsiveHoverImage
              DesktopDefault={YahooFinanceDesktopNotActive}
              DesktopHover={YahooFinanceDesktopActive}
              MobileDefault={YahooFinanceMobileNotActive}
              MobileHover={YahooFinanceMobileActive}
            />
          </Content>
        </OwnUpGridWrapper>
      </section>
    </OwnUpThemeProvider>
  );
};
