import {
  aktivGroteskRegularMixin,
  MOSS_70,
  PropsWithTheme,
  SAGE_100
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

const customMobileBreakpoint = 870;

export const NewBenefitsSection = styled.section`
  background-color: ${MOSS_70};
`;

export const Content = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const Headline = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  text-align: center;
  margin-top: 0;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  }
`;

export const SubHeadline = styled.h3`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-weight: 400;
  margin: 0;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
    text-align: center;
  }
`;

export const BenefitsItems = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
    gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(1358)} {
    justify-content: center;
  }
`;

export const BenefitsItem = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  flex-grow: 1;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2, 1)};
  width: 100%;
  border-radius: 8px;
  border: solid 1px ${SAGE_100};
  svg {
    margin-left: auto;
    align-self: flex-start;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    flex-direction: column;
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3, 4)};
    min-width: 300px;
    max-width: calc(50% - 12px);
    min-height: 252px;
    svg {
      margin-top: auto;
      align-self: center;
      margin-left: 0;
    }
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(1358)} {
    max-width: calc(33% - 16px);
  }
`;

export const ImageWrapper = styled.div`
  min-width: 32px;
  min-height: 32px;
  margin-right: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    width: 64px;
    height: 64px;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const BenefitsSubHeadline = styled.h3`
  ${aktivGroteskRegularMixin}
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 0;
  font-weight: bold;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    text-align: center;
    max-width: 400px;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const CTAWrapper = styled.div`
  text-align: center;
`;

export const DisclaimerSubscript = styled.sup`
  font-size: 0.7rem;
`;
