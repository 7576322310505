import { PropsWithTheme } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const customMobileBreakpoint = 870;

export const Content = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 0, 6)};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    justify-content: space-around;
    flex-direction: row;
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0, 5)};
  }
`;

export const NewsHeadline = styled.h2`
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin: 0;
  margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    font-size: 24px;
    line-height: 32px;
    margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const ImageContainer = styled.div`
  width: calc(50% - 20px);
  text-align: center;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down(customMobileBreakpoint)} {
    .mobile-default {
      display: block;
    }
    .mobile-hover {
      display: none;
    }

    &:hover .mobile-default {
      display: none;
    }
    &:hover .mobile-hover {
      display: block;
    }

    .desktop-default,
    .desktop-hover {
      display: none;
    }
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    width: auto;
    .desktop-default {
      display: block;
    }
    .desktop-hover {
      display: none;
    }

    &:hover .desktop-default {
      display: none;
    }
    &:hover .desktop-hover {
      display: block;
    }
    .mobile-default,
    .mobile-hover {
      display: none;
    }
  }
`;

export const ResponsiveHoverImage = ({
  DesktopDefault,
  DesktopHover,
  MobileDefault,
  MobileHover
}: {
  DesktopDefault: string;
  DesktopHover: string;
  MobileDefault: string;
  MobileHover: string;
}) => {
  return (
    <ImageContainer>
      <div className="desktop-default">
        <DesktopDefault />
      </div>
      <div className="desktop-hover">
        <DesktopHover />
      </div>
      <div className="mobile-default">
        <MobileDefault />
      </div>
      <div className="mobile-hover">
        <MobileHover />
      </div>
    </ImageContainer>
  );
};
