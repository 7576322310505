import React from 'react';
import { BenefitsOfComparing } from '../components/home/benefits-of-comparing';
import { FAQs } from '../components/home/faqs';
import { Hero, HeroMain } from '../components/home/hero';
import { HowOwnUpWorks } from '../components/home/how-ownup-works';
import { InNews } from '../components/home/in-news';
import { MobileCharts } from '../components/home/mobile-charts';
import { NewOverpay } from '../components/home/new-overpay';
import { Reviews } from '../components/home/reviews';
import { Layout } from '../components/layout';

export const Home = () => {
  return (
    <Layout>
      <HeroMain>
        <Hero />
      </HeroMain>
      <InNews />
      <NewOverpay />
      <Reviews />
      <HowOwnUpWorks />
      <BenefitsOfComparing />
      <MobileCharts />
      <FAQs />
    </Layout>
  );
};

export default Home;
