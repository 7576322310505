import { PropsWithTheme, SAGE_100 } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const NewBenefitsSection = styled.section`
  background-color: ${SAGE_100};
`;

export const CTAWrapper = styled.div`
  text-align: center;
  margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px;
  }
`;
