import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
// import Hammer from 'hammerjs';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { homeEeviewImages } from '../../static-images/shared';

import {
  Content,
  Headline,
  PagginationWrapper,
  Review,
  ReviewsSection,
  ReviewsWrapperDesktop,
  ReviewsWrapperMobile
} from './elements';
import { Pagination } from './pagination';

const reviews = [
  {
    image: homeEeviewImages['evan.png'],
    description:
      '“We need to be looking at things differently in the digital age and Own Up’s the only one doing that.”',
    details:
      "Evan says his biggest takeaway from the home buying process is, 'Shop around, shop around, shop around'...",
    name: 'Evan'
  },
  {
    image: homeEeviewImages['andrew.png'],
    description: '“If I had a question, Patrick was just a phone call or text away.”',
    details:
      'Andrew recently had an offer accepted on an affordable housing unit, but due to the condo’s unique circumstance...',
    name: 'Andrew'
  },
  {
    image: homeEeviewImages['michael.png'],
    description: '“There was a human touch...it felt like a concierge.”',
    details:
      'Michael and his wife were living and working in the greater Boston area when they started to think about buying a home...',
    name: 'Michael & Marina'
  }
];
const isBrowser = typeof window !== 'undefined';

export const Reviews = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numOfPages] = useState(3);

  const sliderRef = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      const importHammer = async () => {
        const Hammer = await import('hammerjs');
        const container = sliderRef.current;
        if (container) {
          const hammer = new Hammer.default(container);

          hammer.on('swipeleft', () => {
            setCurrentPage((prevPage) => (prevPage < 3 ? prevPage + 1 : prevPage));
          });

          hammer.on('swiperight', () => {
            setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
          });
          return () => {
            hammer.off('swipeleft');
            hammer.off('swiperight');
          };
        }
      };
      importHammer().catch((error) => {
        console.error('Error importing Hammer.js:', error);
      });
    }
  }, []);
  return (
    <OwnUpThemeProvider>
      <ReviewsSection aria-label={'reviewrs'}>
        <OwnUpGridWrapper>
          <Content>
            <Headline>Why customers love us</Headline>
            <ReviewsWrapperMobile ref={sliderRef}>
              <OwnUpGridContainer variant="legacy">
                <TransitionGroup>
                  {reviews.map(
                    (review, index) =>
                      currentPage === index + 1 && (
                        <CSSTransition key={review.name} timeout={500} classNames="fade">
                          <OwnUpGridItem xs={12} md={4}>
                            <Review
                              image={review.image}
                              description={review.description}
                              details={review.details}
                              name={review.name}
                            />
                          </OwnUpGridItem>
                        </CSSTransition>
                      )
                  )}
                </TransitionGroup>
              </OwnUpGridContainer>
            </ReviewsWrapperMobile>
            <ReviewsWrapperDesktop>
              <OwnUpGridContainer variant="legacy">
                {reviews.map((review, index) => (
                  <OwnUpGridItem xs={12} md={4} key={index}>
                    <Review
                      image={review.image}
                      description={review.description}
                      details={review.details}
                      name={review.name}
                    />
                  </OwnUpGridItem>
                ))}
              </OwnUpGridContainer>
            </ReviewsWrapperDesktop>
            <PagginationWrapper>
              <Pagination
                currentPage={currentPage}
                numOfPages={numOfPages}
                setCurrentPage={setCurrentPage}
              />
            </PagginationWrapper>
          </Content>
        </OwnUpGridWrapper>
      </ReviewsSection>
    </OwnUpThemeProvider>
  );
};
