import { OwnUpGridItem, PropsWithTheme, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import {
  PhoneChartBack as PhoneChartBackComponent,
  PhoneChartFront as PhoneChartFrontComponent
} from '../../static-images/home';

export const MobileChartsSection = styled.section`
  background-color: white;
`;

export const Content = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const Headline = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2.5)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const HeadlineWrapper = styled(OwnUpGridItem)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    text-align: center;
  }
`;

export const ImageWrapper = styled(OwnUpGridItem)`
  display: flex;
  padding-top: 150px !important;
  justify-content: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding-top: 0 !important;
  }
`;

export const PhoneChartFront = styled(PhoneChartFrontComponent)`
  position: relative;
  overflow: visible;
  z-index: 5;
  transform: rotate(-7.6deg);
  left: 40px;
  bottom: 130px;
  ::after {
    content: '';
    display: block;
    width: calc(100% - 7px);
    height: 100%;
    position: absolute;
    left: 3px;
    top: 0;
    z-index: -1;
    border-radius: 60px;
    box-shadow: 0px 20px 40px 0px #4554882b;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    width: 310px;
    left: 113px;
    bottom: 30px;
  }
`;

export const PhoneChartBack = styled(PhoneChartBackComponent)`
  position: relative;
  overflow: visible;
  z-index: 2;
  transform: rotate(1.45deg);
  right: 30px;
  ::after {
    content: '';
    display: block;
    width: calc(100% - 7px);
    height: 100%;
    position: absolute;
    left: 3px;
    top: 0;
    z-index: -1;
    border-radius: 60px;
    box-shadow: 0px 20px 40px 0px #4554882b;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    width: 310px;
    right: 0px;
  }
`;

export const LenderLink = styled.a`
  border: 1px solid ${SAGE_100};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 224px;
  height: 88px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    width: 237px;
    height: 136px;
  }
`;

export const LendersList = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  width: 100%;
  min-width: 1184px;
`;

export const LendersListScrollWrapper = styled.div`
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  padding-left: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    padding-left: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    padding-left: 0;
    position: relative;
    left: unset;
    transform: unset;
    width: auto;
  }
`;

export const LendersListWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 88px;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(12)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    height: 136px;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(18)}px;
  }
`;

export const ResponsiveHoverImage = ({ DesktopDefault }: { DesktopDefault: string }) => {
  return (
    <div>
      <DesktopDefault />
    </div>
  );
};
