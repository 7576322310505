import {
  OwnUpGridContainer,
  OwnUpGridWrapper,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';
import GuaranteedRateLogo from '../../../images/inline-svg/lenders/guaranteed-rate-logo.inline.svg';
import MutualOfOmaha from '../../../images/inline-svg/lenders/mutual-of-omaha.inline.svg';
import NbkcBank from '../../../images/inline-svg/lenders/nbkc-bank.inline.svg';
import NewAmericanFunding from '../../../images/inline-svg/lenders/new-american-funding.inline.svg';
import RocketMortgage from '../../../images/inline-svg/lenders/rocket-mortgage.inline.svg';
import {
  Content,
  Headline,
  HeadlineWrapper,
  ImageWrapper,
  LenderLink,
  LendersList,
  LendersListScrollWrapper,
  LendersListWrapper,
  MobileChartsSection,
  PhoneChartBack,
  PhoneChartFront
} from './elements';

export const MobileCharts = () => {
  return (
    <OwnUpThemeProvider>
      <MobileChartsSection aria-label="mobile-charts">
        <OwnUpGridWrapper>
          <Content>
            <OwnUpGridContainer variant="legacy" style={{ position: 'relative' }}>
              <HeadlineWrapper xs={12}>
                <Headline>We work with top-rated lenders</Headline>
              </HeadlineWrapper>
              <LendersListWrapper>
                <LendersListScrollWrapper>
                  <LendersList>
                    <LenderLink
                      href="https://www.rocketmortgage.com/"
                      target="_blank"
                      aria-label="Read more about Rocket Mortgage"
                    >
                      <RocketMortgage />
                    </LenderLink>
                    <LenderLink
                      href="https://www.mutualofomaha.com/"
                      target="_blank"
                      aria-label="Read more about Mutual of Omaha"
                    >
                      <MutualOfOmaha />
                    </LenderLink>
                    <LenderLink
                      href="https://www.newamericanfunding.com/"
                      target="_blank"
                      aria-label="Read more about NAF"
                    >
                      <NewAmericanFunding />
                    </LenderLink>
                    <LenderLink
                      href="https://www.nbkc.com/"
                      target="_blank"
                      aria-label="Read more about NBKC"
                    >
                      <NbkcBank />
                    </LenderLink>
                    <LenderLink
                      href="https://www.rate.com/"
                      target="_blank"
                      aria-label="Read more about RATE"
                    >
                      <GuaranteedRateLogo />
                    </LenderLink>
                  </LendersList>
                </LendersListScrollWrapper>
              </LendersListWrapper>
              <ImageWrapper xs={12}>
                <PhoneChartFront />
                <PhoneChartBack />
              </ImageWrapper>
            </OwnUpGridContainer>
          </Content>
        </OwnUpGridWrapper>
      </MobileChartsSection>
    </OwnUpThemeProvider>
  );
};
