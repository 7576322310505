import {
  OwnUpFillButtonPrimary,
  OwnUpGridWrapper,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
// import { ChevronUpIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-up';
import React from 'react';
import ContentIcon from '../../../images/inline-svg/benefits/content.inline.svg';
import Expert from '../../../images/inline-svg/benefits/expert.inline.svg';
import Find from '../../../images/inline-svg/benefits/find.inline.svg';
import Save from '../../../images/inline-svg/benefits/save.inline.svg';
import Usage from '../../../images/inline-svg/benefits/usage.inline.svg';

import {
  BenefitsItem,
  BenefitsItems,
  BenefitsSubHeadline,
  Content,
  CTAWrapper,
  DisclaimerSubscript,
  Headline,
  ImageWrapper,
  NewBenefitsSection
} from './elements';

export const BenefitsOfComparing = () => {
  return (
    <OwnUpThemeProvider>
      <NewBenefitsSection aria-label="NewBenefits">
        <OwnUpGridWrapper>
          <Content>
            <Headline>The benefits of shopping with us</Headline>
            <BenefitsItems>
              <BenefitsItem>
                <ImageWrapper>
                  <Save />
                </ImageWrapper>
                <BenefitsSubHeadline>
                  Customers consistently save an average of $28,000 over the life of their loan.
                  <DisclaimerSubscript>1</DisclaimerSubscript>
                </BenefitsSubHeadline>
                {/* <ChevronUpIcon /> */}
              </BenefitsItem>
              <BenefitsItem>
                <ImageWrapper>
                  <Expert />
                </ImageWrapper>
                <BenefitsSubHeadline>
                  Top-rated lenders across the country will compete for your business.
                </BenefitsSubHeadline>
                {/* <ChevronUpIcon /> */}
              </BenefitsItem>
              <BenefitsItem>
                <ImageWrapper>
                  <Find />
                </ImageWrapper>
                <BenefitsSubHeadline>
                  It’s simple and easy. A great mortgage rate is just a few clicks away.{' '}
                </BenefitsSubHeadline>
                {/* <ChevronUpIcon /> */}
              </BenefitsItem>
              <BenefitsItem>
                <ImageWrapper>
                  <ContentIcon />
                </ImageWrapper>
                <BenefitsSubHeadline>
                  Knowledge content, powerful tools, and unbiased advice empower you to make
                  better-informed decisions.
                </BenefitsSubHeadline>
                {/* <ChevronUpIcon /> */}
              </BenefitsItem>
              <BenefitsItem>
                <ImageWrapper>
                  <Usage />
                </ImageWrapper>
                <BenefitsSubHeadline>
                  No hard credit checks. No sales pressure. And our service is completely free to
                  use!{' '}
                </BenefitsSubHeadline>
                {/* <ChevronUpIcon /> */}
              </BenefitsItem>
            </BenefitsItems>
            <CTAWrapper>
              <OwnUpFillButtonPrimary
                href={`${process.env.GATSBY_ONBOARDING}`}
                id="get_started_homepage"
              >
                Find savings now
              </OwnUpFillButtonPrimary>
            </CTAWrapper>
          </Content>
        </OwnUpGridWrapper>
      </NewBenefitsSection>
    </OwnUpThemeProvider>
  );
};
