import {
  OwnUpFillButtonPrimary,
  OwnUpGridWrapper,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';
import { FaqSection } from '../../faq';

import { CTAWrapper, NewBenefitsSection } from './elements';
import faqData from './mock-data.json';

export const FAQs = () => {
  // const getStartedOnClick = () => {
  //   trackingInstance.track('Click new Benefits CTA', { 'page-location': `home-hero-rBenefits` });
  // };
  return (
    <OwnUpThemeProvider>
      <NewBenefitsSection aria-label="FAQa">
        <OwnUpGridWrapper>
          <FaqSection faqData={faqData} title="Top questions people ask" />
          <CTAWrapper>
            <OwnUpFillButtonPrimary
              href={`${process.env.GATSBY_ONBOARDING}`}
              id="get_started_homepage"
            >
              Get started
            </OwnUpFillButtonPrimary>
          </CTAWrapper>
        </OwnUpGridWrapper>
      </NewBenefitsSection>
    </OwnUpThemeProvider>
  );
};
