import {
  OwnUpFillButtonPrimary,
  OwnUpGridWrapper,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';
import Compare from '../../../images/inline-svg/how-ownup-works/compare.inline.svg';
import Save from '../../../images/inline-svg/how-ownup-works/save.inline.svg';
import Shop from '../../../images/inline-svg/how-ownup-works/shop.inline.svg';
import {
  BulleRight,
  BulletLeft,
  Content,
  CTAWrapper,
  DottedLine,
  Headline,
  ImageWrapper,
  NewOverpaySection,
  OverpayHeadline,
  OverpayItem,
  OverpayItems,
  OverpaySubHeadline
} from './elements';

export const HowOwnUpWorks = () => {
  return (
    <OwnUpThemeProvider>
      <NewOverpaySection aria-label="HowOwnupWorks">
        <OwnUpGridWrapper>
          <Content>
            <Headline>How Own Up works</Headline>
            <OverpayItems>
              <DottedLine />
              <BulletLeft />
              <BulleRight />
              <OverpayItem>
                <ImageWrapper>
                  <Shop />
                </ImageWrapper>
                <div>
                  <OverpayHeadline>Shop</OverpayHeadline>
                  <OverpaySubHeadline>
                    Share details about your mortgage needs so we can personalize offers for you.
                  </OverpaySubHeadline>
                </div>
              </OverpayItem>
              <OverpayItem>
                <ImageWrapper>
                  <Compare />
                </ImageWrapper>
                <div>
                  <OverpayHeadline>Select</OverpayHeadline>
                  <OverpaySubHeadline>
                    Review the top-rated lenders that match your needs. See actual, real-time rates.
                  </OverpaySubHeadline>
                </div>
              </OverpayItem>
              <OverpayItem>
                <ImageWrapper>
                  <Save />
                </ImageWrapper>
                <div>
                  <OverpayHeadline>Save</OverpayHeadline>
                  <OverpaySubHeadline>
                    Get a great mortgage deal or negotiate a better deal with your existing lender.
                  </OverpaySubHeadline>
                </div>
              </OverpayItem>
            </OverpayItems>
            <CTAWrapper>
              <OwnUpFillButtonPrimary
                href={`${process.env.GATSBY_ONBOARDING}`}
                id="get_started_homepage"
              >
                Get started
              </OwnUpFillButtonPrimary>
            </CTAWrapper>
          </Content>
        </OwnUpGridWrapper>
      </NewOverpaySection>
    </OwnUpThemeProvider>
  );
};
