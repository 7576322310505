import { Theme, useMediaQuery } from '@material-ui/core';
import { ALOE_100, FOG_60, OwnUpIconButton } from '@rategravity/own-up-component-library';
import { ChevronLeftIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-left';
import { ChevronRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-right';
import React, { Dispatch, SetStateAction } from 'react';
import styled, { useTheme } from 'styled-components';

const NumbersWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 3)};
`;

const NumberButton = styled.button`
  padding: ${({ theme }) => theme.spacing(2.5, 2.5)};
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const BulletButton = styled.div<{ $isActive: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ $isActive }) => ($isActive ? ALOE_100 : FOG_60)};
`;

export const PaginationNumbers = ({
  currentPage,
  numOfPages,
  setCurrentPage
}: {
  currentPage: number;
  numOfPages: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}) => {
  const theme = useTheme() as Theme;
  const limit = useMediaQuery(theme.breakpoints.down('md')) ? 3 : 9; // max page numbers to display in nav
  const center = Math.ceil(limit / 2); // center point of page numbers in nav

  // Determine the starting number of the nav.
  // Assume 1 - meaning we are at or below the center point.
  let navStart = 1;
  // Check if current page is on the last batch of pages displayed in the nav and above the center point of the nav.
  if (currentPage > numOfPages - center) {
    navStart = numOfPages - limit + 1;
    // Last, check if the current page is somewhere in between the first and the last batch of pages.
  } else if (currentPage > center) {
    navStart = currentPage - center + 1;
  }
  const nums = [];
  // add page numbers to the nav from starting point until the limit
  for (; nums.length < limit; navStart++) {
    nums.push(navStart);
  }

  return (
    <NumbersWrapper>
      {nums.map((number) => (
        <NumberButton
          onClick={() => {
            setCurrentPage(number);
          }}
          aria-label="review-page"
          key={number}
        >
          <BulletButton $isActive={number === currentPage} />
        </NumberButton>
      ))}
    </NumbersWrapper>
  );
};

const PaginationWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  button:first-child {
    svg {
      margin-right: 2px;
    }
  }
  button:last-child {
    svg {
      margin-left: 2px;
    }
  }
`;

export const Pagination = ({
  currentPage,
  numOfPages,
  setCurrentPage
}: {
  currentPage: number;
  numOfPages: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}) => (
  <PaginationWrapper aria-label="Reviews pages">
    <OwnUpIconButton
      icon={ChevronLeftIcon}
      name="Backward review button"
      size={2}
      disabled={currentPage === 1}
      onClick={() => {
        setCurrentPage(currentPage - 1);
      }}
    />
    <PaginationNumbers
      numOfPages={numOfPages}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
    <OwnUpIconButton
      icon={ChevronRightIcon}
      name="Forward review button"
      size={2}
      disabled={currentPage === numOfPages}
      onClick={() => {
        setCurrentPage(currentPage + 1);
      }}
    />
  </PaginationWrapper>
);
