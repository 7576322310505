import {
  OwnUpFillButtonPrimary,
  OwnUpGridWrapper,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';
import Hassle from '../../../images/inline-svg/overpay/hassle.inline.svg';
import Save from '../../../images/inline-svg/overpay/save.inline.svg';
import Time from '../../../images/inline-svg/overpay/time.inline.svg';
import {
  Content,
  CTAWrapper,
  Headline,
  ImageWrapper,
  NewOverpaySection,
  OverpayHeadline,
  OverpayItem,
  OverpayItems,
  OverpaySubHeadline,
  SubHeadline
} from './elements';

export const NewOverpay = () => {
  return (
    <OwnUpThemeProvider>
      <NewOverpaySection aria-label="NewOverpay">
        <OwnUpGridWrapper>
          <Content>
            <Headline>Never overpay on your mortgage</Headline>
            <SubHeadline>
              Own Up’s personalized mortgage shopping service finds the perfect mortgage fit for you
            </SubHeadline>
            <OverpayItems>
              <OverpayItem>
                <ImageWrapper>
                  <Save />
                </ImageWrapper>
                <div>
                  <OverpayHeadline>Save Money</OverpayHeadline>
                  <OverpaySubHeadline>
                    Shop like a pro. Be confident that you can beat the market and save thousands.
                  </OverpaySubHeadline>
                </div>
              </OverpayItem>
              <OverpayItem>
                <ImageWrapper>
                  <Time />
                </ImageWrapper>
                <div>
                  <OverpayHeadline>Save Time</OverpayHeadline>
                  <OverpaySubHeadline>
                    Own Up uses AI to curate top-rated mortgage lenders so you can find a great
                    deal—fast.
                  </OverpaySubHeadline>
                </div>
              </OverpayItem>
              <OverpayItem>
                <ImageWrapper>
                  <Hassle />
                </ImageWrapper>
                <div>
                  <OverpayHeadline>Peace of Mind</OverpayHeadline>
                  <OverpaySubHeadline>
                    Let our expertise work for you. Know for sure that you won’t get taken advantage
                    of.
                  </OverpaySubHeadline>
                </div>
              </OverpayItem>
            </OverpayItems>
            <CTAWrapper>
              <OwnUpFillButtonPrimary
                href={`${process.env.GATSBY_ONBOARDING}`}
                id="get_started_homepage"
              >
                Get started
              </OwnUpFillButtonPrimary>
            </CTAWrapper>
          </Content>
        </OwnUpGridWrapper>
      </NewOverpaySection>
    </OwnUpThemeProvider>
  );
};
